import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Spring, animated, config } from 'react-spring'
import { Link } from 'gatsby'

export default class RWLogo extends Component {
  static propTypes = {
    splash: PropTypes.bool,
  }
  static defaultProps = {
    splash: false,
  }

  state = { offset1: 0, offset2: 0, showText: false, splash: false }
  path1 = React.createRef()
  path2 = React.createRef()
  componentDidMount() {
    this.setState({ offset1: this.path1.current.getTotalLength() })
    this.setState({ offset2: this.path2.current.getTotalLength() })
    const { splash } = this.props
    this.setState({ splash })
    if (splash) {
      setTimeout(() => {
        this.setState({ showText: true })
      }, 1500)
      setTimeout(() => {
        this.setState({ splash: false, showText: false })
      }, 3000)
    }
  }

  mouseOut() {
    const { splash } = this.state
    if (!splash) this.setState({ showText: false })
  }

  mouseOver() {
    const { splash } = this.state
    if (!splash) this.setState({ showText: true })
  }

  render() {
    const { offset1, offset2, showText, splash, hover } = this.state
    return (
      <Spring
        native
        from={{
          gridTemplate: this.props.splash
            ? '1fr auto 1fr / 1fr auto 1fr'
            : '0fr auto 1fr / 0fr auto 1fr',
          transform: this.props.splash ? 'scale3d(4, 4, 1)' : 'scale3d(1, 1, 1)',
          background: this.props.splash ? 'rgba(229, 229, 229, 0.78)' : 'rgba(229, 229, 229, 0)',
        }}
        to={{
          gridTemplate: splash ? '1fr auto 1fr / 1fr auto 1fr' : '0fr auto 1fr / 0fr auto 1fr',
          transform: splash ? 'scale3d(4, 4, 1)' : 'scale3d(1, 1, 1)',
          background: splash ? 'rgba(229, 229, 229, 0.78)' : 'rgba(229, 229, 229, 0)',
        }}
      >
        {layout => (
          <Frame style={{ gridTemplate: layout.gridTemplate }}>
            <LinkButton to={this.props.splash ? '/' : '/menu'}>
              <Logo
                style={{
                  transform: layout.transform,
                  background: layout.background,
                  border: layout.border,
                }}
                onMouseEnter={this.mouseOver.bind(this)}
                onMouseLeave={this.mouseOut.bind(this)}
              >
                <svg
                  alt="R"
                  height="5em"
                  viewBox="0 0 45 59"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <Spring
                    native
                    reset={!showText && splash}
                    from={{ dash: offset1 }}
                    to={{ dash: 0 }}
                    config={{ tension: 20, friction: 20 }}
                  >
                    {props => (
                      <animated.path
                        strokeDasharray={offset1}
                        strokeDashoffset={props.dash}
                        ref={this.path1}
                        d="M7 54V12H2V57.5H12V41H23.5C24.1667 41 26 41 28 40.5L37 57.5H31.5C31.5 57.5 24.5 45 24.5 44.5M15 31H23.5C26.6667 31 32.9 29.1 32.5 21.5C32.1 13.9 26.3333 12 23.5 12H12V36H23.5C28.5 35.8333 38.4 32.7 38 21.5C37.6 10.3 28.1667 6.83335 23.5 6.50002H2V2.00002H23.5C30 2.00002 43 5.90001 43 21.5C43 31.2659 37.9053 36.4466 32.5 38.9556C32.5 38.9556 43 58 43 58.5"
                        stroke="#222222"
                        strokeWidth="3"
                      />
                    )}
                  </Spring>
                </svg>
                <Spring
                  native
                  from={{ width: '0vmin' }}
                  to={{
                    width: showText ? '6vmin' : '0vmin',
                  }}
                >
                  {props => <LogoText style={props}>ick</LogoText>}
                </Spring>
                <svg
                  alt="W"
                  height="5em"
                  viewBox="0 0 75 59"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <Spring
                    native
                    reset={!showText && splash}
                    from={{ dash: offset2 }}
                    to={{ dash: 0 }}
                    config={config.molasses}
                  >
                    {props => (
                      <animated.path
                        strokeDasharray={offset2}
                        strokeDashoffset={props.dash}
                        ref={this.path2}
                        d="M12 0.999992C12 1.99999 19.5 28.5 19.5 28.5L17 38L7 1.99999H2L16.5 57.5L17.5 57.5L32 1.99999H43L50.5 28.5L48 38L39.5 6.99999H35.5L22.5 57.5H27.5L37.5 21.5L47.5 57.5L48.5 57.5L63 1.99999H68L53.5 57.5H58.5C58.5 57.5 73.5 1.99999 73.5 0.999992"
                        stroke="#222222"
                        strokeWidth="3"
                      />
                    )}
                  </Spring>
                </svg>
                <Spring
                  native
                  from={{ width: '0vmin', marginLeft: '0vmin' }}
                  to={{
                    width: showText ? '9vmin' : '0vmin',
                    marginLeft: showText ? '-1vmin' : '0vmin',
                  }}
                >
                  {props => <LogoText style={props}>orks</LogoText>}
                </Spring>
              </Logo>
            </LinkButton>
          </Frame>
        )}
      </Spring>
    )
  }
}

const LogoText = styled(animated.span)`
  display: flex;
  justify-content: flex-end;
  font-size: 4vmin;
  overflow: hidden;
  width: 0vmin;
`

const Frame = styled(animated.div)`
  grid-area: 1 / 1 / -1 / -1;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template: 0fr auto 1fr / 0fr auto 1fr;
  overflow: hidden;
`

const Logo = styled(animated.h1)`
  transition: background-color 0.2s ease;
  display: flex;
  align-items: baseline;
  font-weight: normal;
  font-size: 1vmin;
  padding: 1vmin;
  height: 7vmin;
  margin: 0;
`
const LinkButton = styled(Link)`
  grid-area: 2 / 2 / 2 / 2;
  text-decoration: none;
  color: #222;
`
