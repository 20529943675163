import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

const Corridor = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "corridor.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.desktop.childImageSharp.fluid;
      return <BackgroundImg fluid={imageData} />;
    }}
  />
);

const BackgroundImg = styled(Img)`
  position: relative;
  z-index: -5;
  grid-area: 1 / 1 / -1 / -1;
  width: 100vw;
  height: 100vh;
`;

export default Corridor;
