import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Transition, animated } from 'react-spring'

export default class Nav extends Component {
  state = { show: false }
  static propTypes = {
    show: PropTypes.bool,
    page: PropTypes.string,
  }
  static defaultProps = {
    show: false,
    page: '',
  }
  componentDidMount() {
    const { show } = this.props
    this.setState({ show })
    if (!show) setTimeout(() => this.setState({ show: true }), 3000)
  }

  render() {
    const { show } = this.state
    const { page } = this.props
    return (
      <Transition
        native
        from={this.props.show || { opacity: 0, transform: `translate3d(0,-100%,0)` }}
        enter={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
        items={show}
      >
        {display =>
          display &&
          (styles => (
            <Bar style={styles}>
              <NavButton to="/services" current={page === 'services'}>
                Services
              </NavButton>
              <NavButton to="/contact" current={page === 'contact'}>
                Contact
              </NavButton>
            </Bar>
          ))
        }
      </Transition>
    )
  }
}

const Bar = styled(animated.nav)`
  grid-area: nav;
  display: flex;
  justify-content: flex-end;
  height: 7vmin;
  background: rgba(229, 229, 229, 0.78);
`
const NavButton = styled(Link)`
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
  padding: 0 2vmin;
  text-decoration: none;
  color: #222;
  height: 7vmin;
  background: rgba(210, 76, 0, ${props => (props.current ? 0.7 : 0)});
  :hover {
    background: rgba(210, 76, 0, 0.7);
  }
  font-size: 5vmin;
  @media only screen and (min-width: 800px) {
    font-size: 3vmin;
  }
`
