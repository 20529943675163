import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  display: grid;
  grid-template: 7vmin auto / 1fr 100vmin 1fr;
  grid-template-areas: 'nav nav nav' '. center .';
  height: 100vh;
  width: 100vw;
`;

export default Layout;
