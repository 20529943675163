import React, { Component } from 'react'
import styled from 'styled-components'
import NetlifyForm from 'react-netlify-form'

export default class ContactForm extends Component {
  name = React.createRef()
  email = React.createRef()
  message = React.createRef()

  constructor(props) {
    super(props)
    this.state = { emailRed: false, messageRed: false, nameRed: false }
  }

  validate() {
    return new Promise((resolve, reject) => {
      if (this.name.current.value === 'Your Name' || this.name.current.value === '') {
        this.setState({ nameRed: true })
        resolve(true)
      } else this.setState({ nameRed: false })
      if (this.email.current.value === 'Your Email' || this.email.current.value === '') {
        this.setState({ emailRed: true })
        resolve(true)
      } else this.setState({ emailRed: false })
      if (this.message.current.value === 'Message' || this.message.current.value === '') {
        this.setState({ messageRed: true })
        resolve(true)
      } else this.setState({ messageRed: false })
      resolve(false)
    })
  }

  render() {
    const { nameRed, messageRed, emailRed } = this.state
    return (
      <NetlifyForm name="Contact" validate={this.validate.bind(this)}>
        {({ loading, error, success }) => (
          <Form>
            {loading && <>Loading...</>}
            {error && <>Your information was not sent. Please try again.</>}
            {success && <>Thank you for contacting me! I typically respond within 48 hours.</>}
            {!loading && !success && (
              <>
                <Input
                  type="text"
                  name="name"
                  red={nameRed}
                  ref={this.name}
                  defaultValue="Your Name"
                  onFocus={function() {
                    if (this.name.current.value === 'Your Name') this.name.current.value = ''
                  }.bind(this)}
                  onBlur={function() {
                    if (this.name.current.value === '') this.name.current.value = 'Your Name'
                  }.bind(this)}
                />
                <Input
                  type="email"
                  name="email"
                  red={emailRed}
                  ref={this.email}
                  defaultValue="Your Email"
                  onFocus={function() {
                    if (this.email.current.value === 'Your Email') this.email.current.value = ''
                  }.bind(this)}
                  onBlur={function() {
                    if (this.email.current.value === '') this.email.current.value = 'Your Email'
                  }.bind(this)}
                />
                <Textarea
                  type="message"
                  name="message"
                  ref={this.message}
                  defaultValue="Message"
                  red={messageRed}
                />
                <Button type="submit">Send</Button>
              </>
            )}
          </Form>
        )}
      </NetlifyForm>
    )
  }
}

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2vmin;
`

const Textarea = styled.textarea`
  height: 20vmin;
  width: 100%;
  font-family: Inconsolata, monospace;
  color: #222;
  font-size: 5vmin;
  @media only screen and (min-width: 800px) {
    font-size: 3vmin;
  }
  background: none;
  border: ${props => (props.red ? '2px' : '1px')} solid ${props => (props.red ? '#F00' : '#222')};
  margin-top: 2vmin;
`

const Button = styled.button`
  align-self: flex-end;
  border: none;
  background: #222;
  border-radius: 50%;
  height: 20vmin;
  width: 20vmin;
  grid-column-start: 2;
  grid-column-end: 2;
  justify-self: flex-end;
  margin: 2vmin;
  font-family: Inconsolata, monospace;
  color: #ccc;
  font-size: 5vmin;
  @media only screen and (min-width: 800px) {
    font-size: 3vmin;
  }
  @media only screen and (min-width: 800px) {
    height: 10vmin;
    width: 10vmin;
  }
`

const Input = styled.input`
  font-family: Inconsolata, monospace;
  color: #222;
  font-size: 5vmin;
  @media only screen and (min-width: 800px) {
    font-size: 3vmin;
  }
  background: none;
  border: none;
  border-bottom: ${props => (props.red ? '2px' : '1px')} solid
    ${props => (props.red ? '#F00' : '#222')};
`
