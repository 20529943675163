import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Transition, Spring, animated } from 'react-spring'

export default class Card extends Component {
  constructor(props) {
    super(props)
    this.state = { show: false, expand: false }
  }

  static propTypes = {
    title: PropTypes.string,
    color: PropTypes.string.isRequired,
    fontColor: PropTypes.string.isRequired,
    delay: PropTypes.number,
    children: PropTypes.node.isRequired,
  }
  static defaultProps = {
    title: '',
    delay: 0,
  }

  componentDidMount = () => {
    const { delay } = this.props
    setTimeout(() => this.setState({ show: true }), delay)
  }

  expand() {
    this.setState({ expand: true })
  }

  contract() {
    this.setState({ expand: false })
  }

  render() {
    const { show, expand } = this.state
    const { title, children, color, fontColor, arrow } = this.props
    return (
      <Frame
        onMouseEnter={this.expand.bind(this)}
        onClick={this.expand.bind(this)}
        onMouseLeave={this.contract.bind(this)}
      >
        <Transition
          items={show}
          from={{ opacity: 0, transform: 'translate3d(0,50%,0)' }}
          enter={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
          native
        >
          {show =>
            show &&
            (styles => (
              <Spring
                from={{ gridTemplateRows: '1fr 0fr' }}
                to={{ gridTemplateRows: `1fr ${expand ? (arrow ? 1.3 : 2.1) : 0}fr` }}
                native
              >
                {styles2 => (
                  <FlipCard color={color} fontColor={fontColor} style={{ ...styles, ...styles2 }}>
                    <H2>
                      &nbsp;{title}&nbsp;
                      {arrow && (
                        <svg
                          height="1em"
                          viewBox="0 0 103 51"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M93.5 23H0V28H93.5L74 47.5L77.5 51L103 25.5L77.5 0L74 3.5L93.5 23Z"
                            fill="#CCCCCC"
                          />
                        </svg>
                      )}
                    </H2>
                    <Text>{children}</Text>
                  </FlipCard>
                )}
              </Spring>
            ))
          }
        </Transition>
      </Frame>
    )
  }
}

const H2 = styled.h2`
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 10vmin;
  justify-content: center;
  align-items: center;
  font-family: Inconsolata, monospace;
  @media only screen and (min-width: 800px) {
    font-size: 5vmin;
    flex-direction: column;
  }
`
const FlipCard = styled(animated.section)`
  width: 100%;
  height: 100%;
  background: ${props => props.color};
  color: ${props => props.fontColor};
  display: grid;
  grid-template: 1fr 0fr / auto;
`

const Frame = styled.div`
  width: 100%;
  margin: 2vmin;
  height: 40vmin;
  @media only screen and (min-width: 800px) {
    height: 30vmin;
    width: calc(50% - 4vmin);
  }
`

const Text = styled.div`
  margin: 0 2vmin;
  font-size: 5vmin;
  overflow: hidden;
  font-family: Raleway, sans-serif;
  @media only screen and (min-width: 800px) {
    font-size: 3vmin;
  }
`
