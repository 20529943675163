import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default class Grid extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }
  render() {
    const { children } = this.props
    return <ResponsiveGrid>{children}</ResponsiveGrid>
  }
}

const ResponsiveGrid = styled.div`
  grid-area: 2 / 2 / 2 / 2;
  width: 100vmin;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
  @media only screen and (min-width: 800px) {
    align-content: center;
    overflow: hidden;
  }
`
